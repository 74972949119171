import React from 'react';
import '../styles/styles.css';

function Contact() {
  return(
    <div className='Bio'>
      <div className='eempty'/>
      <div className='eempty'/>
      <h3>CONTACT</h3>
      <div className='eempty'/>
      <section >
      <p>Welcome to any questions!</p>
        <p>m.e.pineiro@gmail.com</p>
        <p>+54 9 11 3248 3750</p>
      </section> 
    </div>
  )
}

export default Contact;