import React, { useState, useEffect } from 'react';
import '../styles/styles.css';
import { slide as Menu } from 'react-burger-menu';
import { Link } from 'react-router-dom';

const MyComponent = (props) => {
  const [isOpen, setOpen] = useState(false);
  const [menuWidth, setMenuWidth] = useState('250px'); // Default width

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      // Set menu width based on screen width
      if (screenWidth < 820) {
        setMenuWidth('100%');
      } else {
        setMenuWidth('300px');
      }
    };

    // Call handleResize initially and add event listener
    handleResize();
    window.addEventListener('resize', handleResize);

    // Remove event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleIsOpen = () => {
    setOpen(!isOpen);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    setOpen(false);
  };

  return (
    <Menu
      isOpen={isOpen}
      onOpen={handleIsOpen}
      onClose={handleIsOpen}
      width={menuWidth} // Set dynamic width
    >
      <Link className="menu-item" to="/" onClick={scrollToTop}>
        HOME
      </Link>
      <Link className="menu-item" to="/WORKS" onClick={scrollToTop}>
        WORKS
      </Link>
      <Link className="menu-item" to="/BIO" onClick={scrollToTop}>
        BIO
      </Link>
      <Link className="menu-item" to="/CONTACT" onClick={scrollToTop}>
        CONTACT
      </Link>
    </Menu>
  );
};

export default MyComponent;
