import React from 'react';
import '../styles/styles.css';

function Bio() {
  return(
    <div className='Bio'>
      <div className='eempty'/>
      <h2>BIO</h2>
      <section >
        <p>Born & raised in Buenos Aires, Martin E. Piñeiro has as a background studies at the Film University and courses on documentary, scriptwriting and animation.</p>
        <p>He shot a film, great number of commercials, video clips & short films exploring a variety of genres with a reel that balances narrative, comedy and a strong cinematic style.<br/></p>
        <p>He worked in different corners of the world (Latin America, USA, Europe & Middle East) and won international awards (Cannes, NY, London) for clients such as Coca-Cola, S. C. Johnson, VW, Corona, Moët Hennessy Louis Vuitton, Samsung, Colgate, among many others.</p>
      </section> 
    </div>
  )
}

export default Bio;