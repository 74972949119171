import React, { useEffect, useState } from 'react';
import '../styles/styles.css';
import ReactPlayer from 'react-player';

function Home() {
  useEffect(() => {
    // Prevent scrolling on the entire page
    document.body.style.overflow = 'hidden';
    document.documentElement.style.overflow = 'hidden';

    // Clean up on unmount
    return () => {
      document.body.style.overflow = '';
      document.documentElement.style.overflow = '';
    };
  }, []);

  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 820);
    };

    handleResize(); // Check initially
    window.addEventListener('resize', handleResize);

    // Clean up on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className='wololo'>
      <ReactPlayer
        url="https://vimeo.com/374913654"
        position="absolute"
        width={isSmallScreen ? '1080px' : '100vw'}
        height={isSmallScreen ? '720px' : '100vh'}
        autoPlay
        muted
        loop={true}
        playing
        playsinline={true}
      />
    </div>
  );
}

export default Home;
