import React from 'react';
// import { useEffect, useRef } from 'react';
import '../styles/styles.css'
//import {slite as Menu } from 'react-burguer-menu'

function Header() {
    return(
        <section className='Banner'>
            <h1 > MARTIN E. PIÑEIRO </h1> 
        </section>
    )
};

export default Header;

/*
document.querySelector(".bars__menu").addEventListener("click", animateBars);

//bars__menu

const line1__bars = document.querySelector(".line1__bars-menu");
const line2__bars = document.querySelector(".line2__bars-menu");
const line3__bars = document.querySelector(".line3__bars-menu");

function animateBars() {
    line1__bars.classList.toggle("activeline1__bars-menu");
    line2__bars.classList.toggle("activeline2__bars-menu");
    line3__bars.classList.toggle("activeline3__bars-menu");
}
*/