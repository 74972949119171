import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/header';
import Bio from './components/bio';
import Contact from './components/contact';
import Home from './components/home';
import Works from './components/works';
import Sidebar from './components/sidebar';

function App() {
  return (
    <Router>
      <Header />
      <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/WORKS" element={<Works />} />
        <Route path="/BIO" element={<Bio />} />
        <Route path="/CONTACT" element={<Contact />} />
      </Routes>
    </Router>
  );
}

export default App;

